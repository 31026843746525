import React from 'react';
import { Heading } from '@mablemarket/common-react-lib/build/components';
import useNextLocation from '@mablemarket/common-react-lib/build/modules/NextRouter/hooks/useNextLocation';
import Head from 'next/head';

const NoMatch = () => {
  const location = useNextLocation();
  return (
    <div className="wrapper-inner">
      <Head>
        <title>Not Found | admin-web</title>
      </Head>
      <Heading level={3}>No match for <code>{location.pathname}</code></Heading>
    </div>
  );
}

export default NoMatch;
